import {
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import { doc, getDoc, serverTimestamp, setDoc, updateDoc } from "firebase/firestore";
import React, { useEffect } from "react";
import { db, functions } from "../../firebase.config";

import Iconify from "../Iconify";

import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import Timer from "./Timer";
import { UserAuth } from "../../context/AuthContext";
import Modal from "@mui/material/Modal/Modal";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Fade from "@mui/material/Fade/Fade";
import { Variables } from "../../context/VariableContext";
import { httpsCallable } from "firebase/functions";
import CustomButton from "../CustomButton";


const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  borderRadius: "16px",
  boxShadow: 24,
  p: 4,
};

export default function ReserDesktop({
  event,
  montant,
  ticketsList,
  reduction,
  seance,
}) {
  var randomstring = require("randomstring");
  const { openReservation, setOpenReservation } = Variables();
  const { user } = UserAuth();
  
  const navigate = useNavigate();
  const { eventID } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [loadingF, setLoadingF] = React.useState(true);
  const [total, setTotal] = React.useState(montant);
  const [nomField, setNom] = React.useState("");
  const [prenomField, setPrenom] = React.useState("");
  const [emailField, setEmail] = React.useState("");
  const [confirmEmailField, setConfirmEmail] = React.useState("");
  const [fieldError, setError] = React.useState(false);
  const [confirmError, setConfirmError] = React.useState(false);
  const [fee, setFee] = React.useState(0);
  const [moyenPaie, setMoyenpaie] = React.useState(0);

  const handleRadioChange = (event) => {
    var tmp = event.target.value;
    if (parseInt(tmp) === 0) {
      setFee(0)
    } else if (parseInt(tmp) === 1) {
      setFee(1.5)
    } else {
      setFee(2.5)
    }
    setMoyenpaie(parseInt(event.target.value));
  };

  useEffect(() => {
    (async () => {
      try {
        setLoadingF(true);
        console.log(seance)
        const docRef0 = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          setEmail(docSnap.data().email);
          setNom(docSnap.data().nom);
          setPrenom(docSnap.data().prenom);
          setConfirmEmail(docSnap.data().email);
          console.log("start");
          // setLoading(false);
        }
      } catch (error) {
        console.log(error);
      }
      setLoadingF(false);
    })();
  }, []);

  function getCookie(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
    return null;
  }
  //fonction de paiement
  const pay = async () => {
    setLoading(true);
    var montant = 0;
    if (nomField === "" || prenomField === "" || emailField === "") {
      setError(true);
      setLoading(false);
      return;
    }
    if (emailField !== confirmEmailField) {
      setConfirmError(true);
      setLoading(false);
      return;
    }

    console.log(seance)
    const affiliate = getCookie(`affId-${eventID}`);
    try {
      const addMessage = httpsCallable(functions, "initiatePay");
      const result = await addMessage({ tickets: ticketsList, uid: user ? user.uid : null, firstname: prenomField, lastname: nomField, mobile: '', email: emailField, eventid: eventID, from: 1, discount: reduction, fee: fee, seance: seance, track: affiliate ? { aff: affiliate } : null });
      console.log("-----------------result payment----------------")
      console.log(result.data)
      if (result.data?.status === 'ok') {
        window.location.href = result.data.link
      } else {
        setLoading(false);
        window.location.replace(`http://localhost:3001/fr/event/${eventID}?order=unavalable`)
        // navigate(`/fr/event/${eventID}?stateOrder=unavalable`)
        
      }
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "90vh",
        width: "65%",
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Stack spacing={3} justifyContent={'center'} alignItems={'center'}>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                <strong>Voulez-vous annuler la commande ?</strong>
              </Typography>
              <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                Voulez-vous vraiment quitter le règlement ? Les billets que vous
                avez sélectionnés ne seront peut-être plus disponibles
                ultérieurement.
              </Typography>
              <Box
                sx={{ width: "100%", display: "flex", justifyContent: "center", columnGap: 2 }}
              >
                <CustomButton type={'secondaire'} buttonText={'Annuler'} onClick={() => {
                  window.location.reload();
                }} />
                <CustomButton type={'primaire'} buttonText={'rester'} onClick={() => {
                  setOpen(false);
                }} />

              </Box>
            </Stack>



          </Box>
        </Fade>
      </Modal>
      {loadingF ? (
        <CircularProgress />
      ) : (
        <Box
          sx={{
            bgcolor: "white",
            borderRadius: " 10px",
            zIndex: 1,
          }}
        >
          <Grid container>
            {/** info de la commande */}
            <Grid item xs={12} sm={8}>
              <Grid container>
                <Grid item xs={12}>
                  <Stack padding={1} direction="row">
                    <IconButton onClick={handleOpen}>
                      <Iconify
                        icon="solar:close-circle-outline"
                        sx={{ width: 24, height: 24 }}
                      />
                    </IconButton>
                    <Stack
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <Typography
                        variant="h6"
                        fontWeight={600}
                        textAlign="center"
                        color="#999999"
                      >
                        Commander
                      </Typography>
                      <Timer />
                    </Stack>
                  </Stack>

                  <Divider />
                </Grid>
                {/** information de facturation et payer avec */}
                {
                  !loading ? <Grid
                    sx={{ overflowX: "auto", overflowY: "auto" }}
                    item
                    xs={12}
                    paddingX={10}
                    paddingY={2}
                    maxHeight={{ xs: 300, sm: 350, xl: 500 }}
                  >
                    <Typography
                      paddingY={2}
                      paddingBottom={2}
                      variant="h5"
                      fontWeight={600}
                    >
                      Informations de facturation
                    </Typography>
                    <Stack>
                      <Typography></Typography>
                    </Stack>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        {fieldError && nomField === "" ? (
                          <TextField
                            value={nomField}
                            onChange={(e) => setNom(e.target.value)}
                            fullWidth
                            label="Nom"
                            size="medium"
                            error
                            helperText="Merci de remplir ce champs."
                          />
                        ) : (
                          <TextField
                            value={nomField}
                            onChange={(e) => setNom(e.target.value)}
                            fullWidth
                            label="Nom"
                            size="medium"
                          />
                        )}
                      </Grid>
                      <Grid item xs={6}>
                        {fieldError && prenomField === "" ? (
                          <TextField
                            value={prenomField}
                            onChange={(e) => setPrenom(e.target.value)}
                            fullWidth
                            label="Prenom"
                            error
                            helperText="Merci de remplir ce champs."
                          />
                        ) : (
                          <TextField
                            value={prenomField}
                            onChange={(e) => setPrenom(e.target.value)}
                            fullWidth
                            label="Prenom"
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {fieldError && emailField === "" ? (
                          <TextField
                            type="email"
                            value={emailField}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            label="Email"
                            error
                            helperText="Une adresse email est requise."
                          />
                        ) : (
                          <TextField
                            value={emailField}
                            onChange={(e) => setEmail(e.target.value)}
                            fullWidth
                            label="Email"
                          />
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        {fieldError && confirmEmailField === "" ? (
                          <TextField
                            value={confirmEmailField}
                            onChange={(e) => setConfirmEmail(e.target.value)}
                            fullWidth
                            label="Confirmer l'email "
                            error
                            helperText="Une adresse email est requise"
                          />
                        ) : (
                          <TextField
                            value={confirmEmailField}
                            onChange={(e) => setConfirmEmail(e.target.value)}
                            fullWidth
                            label="Confirmer l'email "
                            error={confirmError ? true : false}
                            helperText={
                              confirmError
                                ? "Les emails doivent être identiques."
                                : ""
                            }
                          />
                        )}
                      </Grid>
                    </Grid>
                    <Stack paddingY={5}>
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Tenez-moi au courant des autres événements et des nouvelles de cet organisateur."
                      />
                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Envoyez-moi des e-mails sur les meilleurs événements ayant lieu dans mes environ ou en ligne."
                      />
                    </Stack>
                    {reduction && (montant - reduction.value) === 0 ? <></> : montant === 0 ? <></> :
                      <>
                        <Typography variant="h5" fontWeight={600}>
                          Payer avec
                        </Typography>

                        <Box
                          marginTop={2}
                          marginBottom={5}
                        >
                          <RadioGroup
                            aria-labelledby="demo-radio-buttons-group-label"
                            value={moyenPaie}
                            name="radio-buttons-group"
                            onChange={(e) => {
                              handleRadioChange(e);
                            }}
                          >
                            <Stack alignItems={'center'} spacing={1}>
                              <Grid
                                container
                                sx={{ borderRadius: '16px', bgcolor: '#f2f2f2' }}
                                paddingY={2} paddingX={1}
                              >
                                <Grid item xs={1} paddingX={1}>
                                  <FormControlLabel value={0} control={<Radio />} />
                                </Grid>
                                <Grid item xs={8} display="center" alignItems="center">
                                  Mobile Money & Wave
                                </Grid>
                                <Grid item xs={2}></Grid>
                              </Grid>
                              <Grid
                                container
                                sx={{ borderRadius: '16px', bgcolor: '#f2f2f2' }}
                                paddingY={2} paddingX={1}
                              >
                                <Grid item xs={1} paddingX={1}>
                                  {" "}
                                  <FormControlLabel value={1} control={<Radio />} />
                                </Grid>
                                <Grid item xs={10} display="center" alignItems="center">
                                  Carte de débit ou de crédit
                                </Grid>
                                <Grid item xs={1} display="center" alignItems="center">
                                  <Iconify
                                    icon="bi:credit-card-2-back"
                                    sx={{ width: 30, height: 30 }}
                                  />
                                </Grid>
                                {moyenPaie === 1 ? (
                                  <Grid container spacing={2} paddingY={1} paddingX={3}>
                                    <Grid item xs={12}>
                                      <TextField
                                        fullWidth
                                        label="Numéro de la carte"
                                        size="medium"
                                      />
                                    </Grid>
                                    <Grid item xs={8}>
                                      <TextField fullWidth label="Date d'experation" />
                                    </Grid>
                                    <Grid item xs={4}>
                                      <TextField fullWidth label="CVV" />
                                    </Grid>
                                  </Grid>
                                ) : null}
                              </Grid>
                              <Grid
                                container
                                sx={{ borderRadius: '16px', bgcolor: '#f2f2f2' }}
                                paddingY={2} paddingX={1}
                              >
                                <Grid item xs={1} paddingX={1}>
                                  {" "}
                                  <FormControlLabel value={2} control={<Radio />} />
                                </Grid>
                                <Grid item xs={10} display="center" alignItems="center">
                                  PayPal
                                </Grid>
                                <Grid item xs={1} display="center" alignItems="center">
                                  <Iconify
                                    icon="logos:paypal"
                                    sx={{ width: 30, height: 30 }}
                                  />
                                </Grid>
                              </Grid>
                            </Stack>

                          </RadioGroup>
                        </Box>


                      </>}
                  </Grid> : <Grid
                    sx={{ overflowX: "auto", overflowY: "auto" }}
                    item
                    xs={12}
                    paddingX={10}
                    paddingY={2}
                    maxHeight={500}
                    minHeight={300}
                  >
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                      <CircularProgress sx={{ color: "5E17EB" }} size={100} />
                    </Box>

                  </Grid>
                }

                {/** Bouton de commander */}
                <Grid item xs={12}>
                  <Divider />
                  <Stack padding={2}>
                    <CustomButton disabled={loading} type={'primaire'} buttonText={'Comfirmer la commande'} onClick={pay} />
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
            {/** resumé de la commande */}
            <Grid item xs={12} sm={4} sx={{ bgcolor: "#F2ECFD", borderRadius: " 10px", }}>
              <Grid container>
                <Grid item xs={12}>
                  <Card elevation={0}>
                    {event && (
                      <CardMedia
                        component="img"
                        sx={{ width: "100%", objectFit: "cover" }}
                        height="200"
                        image={event.idVisuel}
                        title="green iguana"
                      />
                    )}
                  </Card>
                </Grid>
                <Grid item xs={12}>
                  <Stack spacing={1} padding={2}>
                    <Typography>Résumé de la commande</Typography>
                    {ticketsList.map((billet, index) => (
                      <Box color="#999999">
                        <Stack direction="row" justifyContent="space-between">
                          <Typography>
                            {billet.quantite}x {billet.nom}
                          </Typography>
                          <Typography>{billet.prix} CFA</Typography>
                        </Stack>
                      </Box>
                    ))}

                    <Divider />
                    {(moyenPaie === 1 || moyenPaie === 2) && (
                      <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize={14}>Frais de service ({fee}%)</Typography>
                        <Typography fontSize={14}>+{reduction ? (montant - reduction.value) * fee / 100 : montant * fee / 100} CFA</Typography>
                      </Stack>
                    )}
                    {reduction && (
                      <Stack direction="row" justifyContent="space-between">
                        <Typography fontSize={14}>Réduction</Typography>
                        <Typography fontSize={14}>
                          -{reduction.value} CFA
                        </Typography>
                      </Stack>
                    )}

                    <Stack direction="row" justifyContent="space-between">
                      <Typography>Total à payer</Typography>
                      <Typography>
                        {reduction ? montant - reduction.value + ((montant - reduction.value) * fee / 100) : montant + (montant * fee / 100)} CFA
                      </Typography>
                    </Stack>
                  </Stack>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}
