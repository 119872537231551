import {
  Box,
  Container,
  Grid,
  Link,
  Skeleton,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { collection, doc, getDoc, getDocs, limit, orderBy, query, where } from "firebase/firestore";
import React from "react";
import { db } from "../../firebase.config";
import EventCard from "../EventCard";
import CustomButton from "../CustomButton";

const Today = () => {
  const [loading, setLoading] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const createEventList = (doc) => {
    setEvents((events) => [...events, doc]); // ajouter a la fin
  };
  React.useEffect(() => {
    (async () => {
      try {
        const today = new Date();
        setLoading(true);
        setEvents([]);
        const q = query(
          collection(db, "evenement"),
          where("status", "==", 1),
          where(
            "dateDebut",
            ">=",
            new Date(
              today.getFullYear(),
              today.getMonth(),
              today.getDate()
            )
          ),
          where(
            "dateDebut",
            "<",
            new Date(today.getTime() + 24 * 60 * 60 * 1000)
          ),
          orderBy("dateDebut", "asc"),
          limit(6)
        );
        const querySnapshot = await getDocs(q);
        // querySnapshot.empty()
        console.log(querySnapshot);
        // setEvents(querySnapshot.docs);
        querySnapshot.forEach(async (d) => {
          // get event organisateur

          const querySnapshot1 = await getDoc(doc(db, "organisations", d.data().orgId));

          setEvents((events) => [
            ...events,
            { infos: d, org: querySnapshot1.data().nomOrg },
          ]);
          // setLoading(false);
        })
        //setLoading(false);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, []);

  return (
    <Box sx={{ mt: 5 }}>
      {loading ? (
        <Grid container spacing={5} paddingY={2}>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rectangular" height={118} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rectangular" height={118} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Skeleton variant="rectangular" height={118} />
            <Box sx={{ pt: 0.5 }}>
              <Skeleton />
              <Skeleton width="60%" />
            </Box>
          </Grid>
        </Grid>
      ) : (
        <Grid container rowSpacing={5} paddingY={2}>
          {events.length === 0 && !loading ?
            <Grid item xs={12}>

              <Stack
                display="flex"
                justifyContent="center"
                alignItems="center"
                width={"100%"}
                spacing={1}
              >
                <Typography variant="h6">Oups c'est calme pour le moment</Typography>
                <Typography variant="body1" textAlign='center'>Il semble que la fête soit terminée pour aujourd'hui. Restez a l'âffut pour lesprochains événements</Typography>

              </Stack>
            </Grid> : events.map((event, index) => (
              <Grid item xs={12} md={4} sx={{ height: `100%` }}>
                <EventCard
                  key={event.id}
                  ev={event.infos}
                  img={event.infos.data().idVisuel}
                  name={event.infos.data().nomEvent}
                  lieu={event.infos.data().lieu_event}
                  city={event.infos.data().city}
                  type={event.infos.data().type}
                  debut={event.infos.data().dateDebut}
                  organisateur={event.org}
                  eventID={event.infos.id}
                  sx={{ height: `100%` }}
                />
              </Grid>
            ))}
        </Grid>
      )}
      <Box
        paddingY={5}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        {
          events.length !== 0 && <Link href={`/fr/all--city/events--today`} color="inherit" underline="none"><CustomButton
            backgroundColor="#1F1F1F"
            color="#fff"
            buttonText="Afficher plus"
            heroBtn={true}
          /></Link>
        }
      </Box>
    </Box>
  );
};

export default Today;
