import {
  Box,
  Button,
  Card,
  CardMedia,
  Checkbox,
  CircularProgress,
  Container,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Radio,
  RadioGroup,
  Skeleton,
  Stack,
  styled,
  TextField,
  Typography,
  IconButton,
} from "@mui/material";
import {
  addDoc,
  collection,
  doc,
  Firestore,
  getDoc,
  getDocs,
  limit,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
} from "firebase/firestore";
import React, { useEffect } from "react";
import { db, functions } from "../../firebase.config";
import CustomButton from "../CustomButton";
import EventCard from "../EventCard";
import Iconify from "../Iconify";
import { PayWithPayD } from "../paiement/PayWithPaydunya";
import { useParams } from "react-router-dom";
import Timer from "./Timer";
import { UserAuth } from "../../context/AuthContext";
import Modal from "@mui/material/Modal/Modal";
import Backdrop from "@mui/material/Backdrop/Backdrop";
import Fade from "@mui/material/Fade/Fade";
import { httpsCallable } from "firebase/functions";
import { Sheet } from 'react-modal-sheet';


var paydunya = require("paydunya");

/*function payWithPaydunya(btn) {
  payWithPaydunya
    .setup({
      selector: btn,
      url: "https://choptonticket/paydunya-api",
      method: "GET",
      displayMode: payWithPaydunya.DISPLAY_IN_POPUP,
      beforeRequest: function () {
        console.log("About to get a token and the url");
      },
      onSuccess: function (token) {
        console.log("Token: " + token);
      },
      onTerminate: function (ref, token, status) {
        console.log(ref);
        console.log(token);
        console.log(status);
      },
      onError: function (error) {
        alert("Unknown Error ==> ", error.toString());
      },
      onUnsuccessfulResponse: function (jsonResponse) {
        console.log("Unsuccessful response ==> " + jsonResponse);
      },
      onClose: function () {
        console.log("Close");
      },
    })
    .requestToken();
}*/

var setup = new paydunya.Setup({
  masterKey: "DNrUjYFV-6HiQ-NNu5-DAgU-WizSswjzZW6I",
  privateKey: "test_private_Qyqe0MaokWBMmU1m5d1kNHcj7fs",
  publicKey: "test_public_8FAyuNRDgwV2v8Yujc8QHvlYtuS",
  token: "vT8PF5dQR3zx7r9OcplJ",
  mode: "test",
  cancelURL: "http://localhost:5000/",
});

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  borderRadius: "10px",
  boxShadow: 24,
  p: 4,
};
const styleRecap = {
  position: "absolute",
  top: "0",
  left: "0",
  width: "100%",
  height: "100%",
  bgcolor: "background.paper",
  p: 2,
};

export default function ReserMobile2({ event, montant, ticketsList, seance, reduction, widget }) {
  const { user } = UserAuth();
  const { eventID } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [loadingF, setLoadingF] = React.useState(false);
  const [total, setTotal] = React.useState(montant);
  const [check, setCheck] = React.useState(false);
  const [nomField, setNom] = React.useState("");
  const [prenomField, setPrenom] = React.useState("");
  const [emailField, setEmail] = React.useState("");
  const [confirmEmailField, setConfirmEmail] = React.useState("");
  const [fieldError, setError] = React.useState(false);
  const [confirmError, setConfirmError] = React.useState(false);
  const [fee, setFee] = React.useState(0);
  const [moyenPaie, setMoyenpaie] = React.useState(0);

  const handleRadioChange = (event) => {
    var tmp = event.target.value;
    if (parseInt(tmp) === 0) {
      setFee(0)
    } else if (parseInt(tmp) === 1) {
      setFee(1.5)
    } else {
      setFee(2.5)
    }
    setMoyenpaie(parseInt(event.target.value));
  };

  useEffect(() => {
    (async () => {
      try {
        setLoadingF(true);
        const docRef0 = doc(db, "users", user.uid);
        const docSnap = await getDoc(docRef0);
        if (docSnap.exists()) {
          setEmail(docSnap.data().email);
          setNom(docSnap.data().nom);
          setPrenom(docSnap.data().prenom);
          setConfirmEmail(docSnap.data().email);
          console.log("start");
          // setLoading(false);
        } else {
          const docRef0 = doc(db, "organisateurs", user.uid);
          const docSnap = await getDoc(docRef0);
          if (docSnap.exists()) {
            setEmail(docSnap.data().email);
            setConfirmEmail(docSnap.data().email);
            setNom(docSnap.data().nom);
            setPrenom(docSnap.data().prenom);

            // setLoading(false);
          }
        }
      } catch (error) {
        console.log(error);
      }
      setLoadingF(false);
    })();
  }, [user]);

  //Creation de la facture
  //fonction de paiement
  function getCookie(name) {
    const match = document.cookie.match(new RegExp('(^| )' + name + '=([^;]+)'));
    if (match) return match[2];
    return null;
  }
  //fonction de paiement
  const pay = async () => {
    setLoading(true);
    var montant = 0;
    if (nomField === "" || prenomField === "" || emailField === "") {
      setError(true);
      setLoading(false);
      return;
    }
    if (emailField !== confirmEmailField) {
      setConfirmError(true);
      setLoading(false);
      return;
    }

    console.log(seance)
    const affiliate = getCookie(`affId-${eventID}`);
    const source = getCookie(`source-${eventID}`);
    try {
      const addMessage = httpsCallable(functions, "initiatePay");
      const result = await addMessage({ tickets: ticketsList, uid: user ? user.uid : null, firstname: prenomField, lastname: nomField, mobile: '', email: emailField, eventid: eventID, source, from: widget ? 2 : 1, discount: reduction, fee: fee, seance: seance, track: affiliate ? { aff: affiliate } : null });
      console.log("-----------------result payment----------------")
      console.log(result.data)
      if (result.data?.status === 'ok') {
        window.location.href = result.data.link
      } else {
        setLoading(false);
        window.location.replace(`http://localhost:3001/fr/event/${eventID}?order=unavalable`)
        // navigate(`/fr/event/${eventID}?stateOrder=unavalable`)

      }
    } catch (error) {
      console.log(error)
      setLoading(false);
    }
  };

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  /** recapitulatif modal */
  const [openRecap, setOpenRecap] = React.useState(false);
  const handleOpenRecap = () => setOpenRecap(true);
  const handleCloseRecap = () => setOpenRecap(false);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <Box sx={style}>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Voulez-vous quitter la commande ?
            </Typography>
            <Typography id="transition-modal-description" sx={{ mt: 2 }}>
              Voulez-vous vraiment quitter le règlement ? Les articles que vous
              avez sélectionnés ne seront peut-être plus disponibles
              ultérieurement.
            </Typography>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "right" }}
            >
              <Button
                onClick={() => {
                  setOpen(false);
                }}
              >
                Continuer
              </Button>
            </Box>
          </Box>
        </Fade>
      </Modal>
      {loadingF ? (
        <Stack paddingY={10}>
          <CircularProgress size={80} sx={{ color: "#5E17EB" }} />
        </Stack>
      ) : (
        <Box
          sx={{
            bgcolor: "white",
          }}
        >
          <Grid container>
            {/** info de la commande */}
            <Grid item xs={12} >
              <Grid container>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
                {
                  !loading ?
                    <Grid
                      sx={{ overflowX: "auto", overflowY: "auto" }}
                      item
                      xs={12}
                      paddingX={1}
                      paddingBottom={10}
                      // maxHeight={800}
                      height={600}
                    >
                      <Typography
                        paddingY={2}
                        paddingBottom={2}
                        variant="h6"
                        fontWeight={500}
                      >
                        Informations de facturation
                      </Typography>
                      <Stack>
                        <Typography></Typography>
                      </Stack>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          {fieldError && nomField === "" ? (
                            <TextField
                              value={nomField}
                              onChange={(e) => setNom(e.target.value)}
                              fullWidth
                              label="Nom"
                              size="medium"
                              error
                              helperText="Merci de remplir ce champs."
                            />
                          ) : (
                            <TextField
                              value={nomField}
                              onChange={(e) => setNom(e.target.value)}
                              fullWidth
                              label="Nom"
                              size="medium"
                            />
                          )}
                        </Grid>
                        <Grid item xs={6}>
                          {fieldError && prenomField === "" ? (
                            <TextField
                              value={prenomField}
                              onChange={(e) => setPrenom(e.target.value)}
                              fullWidth
                              label="Prenom"
                              error
                              helperText="Merci de remplir ce champs."
                            />
                          ) : (
                            <TextField
                              value={prenomField}
                              onChange={(e) => setPrenom(e.target.value)}
                              fullWidth
                              label="Prenom"
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {fieldError && emailField === "" ? (
                            <TextField
                              type="email"
                              value={emailField}
                              onChange={(e) => setEmail(e.target.value)}
                              fullWidth
                              label="Email"
                              error
                              helperText="Une adresse email est requise."
                            />
                          ) : (
                            <TextField
                              value={emailField}
                              onChange={(e) => setEmail(e.target.value)}
                              fullWidth
                              label="Email"
                            />
                          )}
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          {fieldError && confirmEmailField === "" ? (
                            <TextField
                              value={confirmEmailField}
                              onChange={(e) => setConfirmEmail(e.target.value)}
                              fullWidth
                              label="Confirmer l'email "
                              error
                              helperText="Une adresse email est requise"
                            />
                          ) : (
                            <TextField
                              value={confirmEmailField}
                              onChange={(e) => setConfirmEmail(e.target.value)}
                              fullWidth
                              label="Confirmer l'email "
                              error={confirmError ? true : false}
                              helperText={
                                confirmError
                                  ? "Les emails doivent être identiques."
                                  : ""
                              }
                            />
                          )}
                        </Grid>
                      </Grid>
                      <Stack paddingY={5}>
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="Tenez-moi au courant des autres événements et des nouvelles de cet organisateur."
                        />
                        <FormControlLabel
                          control={<Checkbox defaultChecked />}
                          label="Envoyez-moi des e-mails sur les meilleurs événements ayant lieu dans mes environ ou en ligne."
                        />
                      </Stack>
                      {reduction && (montant - reduction.value) === 0 ? <></> : montant === 0 ? <></> :
                        <>
                          <Typography variant="h6" fontWeight={500}>
                            Payer avec
                          </Typography>

                          <Box
                            marginTop={2}
                            marginBottom={5}
                          >
                            <RadioGroup
                              aria-labelledby="demo-radio-buttons-group-label"
                              value={moyenPaie}
                              name="radio-buttons-group"
                              onChange={(e) => {
                                handleRadioChange(e);
                              }}
                            >
                              <Stack alignItems={'center'} spacing={1}>
                                <Grid
                                  container
                                  sx={{ borderRadius: '16px', bgcolor: '#f2f2f2' }}
                                  paddingY={2} paddingX={1}
                                >
                                  <Grid item xs={1} paddingX={1}>
                                    <FormControlLabel value={0} control={<Radio />} />
                                  </Grid>
                                  <Grid item xs={8} display="center" alignItems="center">
                                    Mobile Money & Wave
                                  </Grid>
                                  <Grid item xs={2}></Grid>
                                </Grid>
                                <Grid
                                  container
                                  sx={{ borderRadius: '16px', bgcolor: '#f2f2f2' }}
                                  paddingY={2} paddingX={1}
                                >
                                  <Grid item xs={1} paddingX={1}>
                                    {" "}
                                    <FormControlLabel value={1} control={<Radio />} />
                                  </Grid>
                                  <Grid item xs={10} display="center" alignItems="center">
                                    Carte de débit ou de crédit
                                  </Grid>
                                  <Grid item xs={1} display="center" alignItems="center">
                                    <Iconify
                                      icon="bi:credit-card-2-back"
                                      sx={{ width: 30, height: 30 }}
                                    />
                                  </Grid>
                                  {moyenPaie === 1 ? (
                                    <Grid container spacing={2} paddingY={1} paddingX={3}>
                                      <Grid item xs={12}>
                                        <TextField
                                          fullWidth
                                          label="Numéro de la carte"
                                          size="medium"
                                        />
                                      </Grid>
                                      <Grid item xs={8}>
                                        <TextField fullWidth label="Date d'experation" />
                                      </Grid>
                                      <Grid item xs={4}>
                                        <TextField fullWidth label="CVV" />
                                      </Grid>
                                    </Grid>
                                  ) : null}
                                </Grid>
                                <Grid
                                  container
                                  sx={{ borderRadius: '16px', bgcolor: '#f2f2f2' }}
                                  paddingY={2} paddingX={1}
                                >
                                  <Grid item xs={1} paddingX={1}>
                                    {" "}
                                    <FormControlLabel value={2} control={<Radio />} />
                                  </Grid>
                                  <Grid item xs={10} display="center" alignItems="center">
                                    PayPal
                                  </Grid>
                                  <Grid item xs={1} display="center" alignItems="center">
                                    <Iconify
                                      icon="logos:paypal"
                                      sx={{ width: 30, height: 30 }}
                                    />
                                  </Grid>
                                </Grid>
                              </Stack>

                            </RadioGroup>
                          </Box>


                        </>}


                      <FormControlLabel
                        control={<Checkbox defaultChecked />}
                        label="Envoyez-moi des e-mails sur les meilleurs événements ayant lieu dans mes environ ou en ligne."
                      />
                    </Grid>
                    : <Grid
                      sx={{ overflowX: "auto", overflowY: "auto" }}
                      item
                      xs={12}
                      paddingX={10}
                      paddingY={2}
                      maxHeight={500}
                      minHeight={300}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}>
                        <CircularProgress sx={{ color: "5E17EB" }} size={100} />
                      </Box>

                    </Grid>
                }
                <Grid item xs={12}>
                  {/** button commande */}
                  <Card
                    elevation={5}
                    sx={{
                      position: "fixed",
                      bottom: 0,
                      left: 0,
                      right: 0,
                      padding: 2,
                      borderTopRightRadius: '10px',
                      borderTopLeftRadius: '10px'
                    }}
                  >
                    <Stack direction="row" justifyContent="end">
                      <Stack direction="row" spacing={1}>
                        <Stack
                          direction="row"
                          spacing={0.1}
                          onClick={() => {
                            handleOpenRecap();
                          }}
                        >
                          <Typography>récapitulatif</Typography>
                          <Iconify
                            icon="ic:baseline-keyboard-arrow-up"
                            sx={{ width: 24, height: 24 }}
                          />
                        </Stack>
                        <Sheet isOpen={openRecap} onClose={() => setOpenRecap(false)} snapPoints={[400]}>
                          <Sheet.Container>
                            <Sheet.Header />
                            <Sheet.Content>
                              <Box >
                                <Grid container>
                                  <Grid item xs={12}>
                                    <Box display="flex" justifyContent="end">
                                    </Box>
                                    <Stack spacing={2} padding={2}>
                                      <Typography fontSize={17} fontWeight={500}>
                                        Résumé de la commande
                                      </Typography>
                                      {ticketsList.map((billet, index) => (
                                        <Box color="#999999">
                                          <Stack
                                            direction="row"
                                            justifyContent="space-between"
                                          >
                                            <Typography>
                                              {billet.quantite}x {billet.nom}
                                            </Typography>
                                            <Typography>{billet.prix} CFA</Typography>
                                          </Stack>
                                        </Box>
                                      ))}

                                      <Divider />

                                      <Stack
                                        color="#999999"
                                        direction="row"
                                        justifyContent="space-between"
                                      >
                                        <Typography>Frais de service</Typography>
                                        <Typography>{total} CFA</Typography>
                                      </Stack>
                                      <Divider />

                                      <Stack direction="row" justifyContent="space-between">
                                        <Typography fontSize={17} fontWeight={500}>Total</Typography>
                                        <Typography fontSize={17} fontWeight={500}>{total} CFA</Typography>
                                      </Stack>
                                    </Stack>
                                  </Grid>
                                </Grid>
                              </Box>
                            </Sheet.Content>
                          </Sheet.Container>
                          <Sheet.Backdrop />
                        </Sheet>
                        <Typography
                          fontSize={17}
                          fontWeight={500}
                          align="center"
                          paddingBottom={1}
                        >
                          {montant} CFA
                        </Typography>
                      </Stack>
                    </Stack>

                    <CustomButton fullWidth disabled={loading} type={'primaire'} buttonText={'Comfirmer la commande'} onClick={pay} />


                  </Card>
                </Grid>
              </Grid>
            </Grid>
            {/** modal recap */}

            {/** resumé de la commande
             * <Grid item xs={12} sm={4} sx={{ bgcolor: "#F2ECFD" }}>
              
            </Grid>
             * 
             */}
          </Grid>
        </Box>
      )}
    </Box>
  );
}
