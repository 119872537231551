/* eslint-disable no-nested-ternary */
/* eslint-disable object-shorthand */
import { Box, Button, CircularProgress, styled, Switch } from "@mui/material";
import React from "react";
import Iconify from "./Iconify";

const CustomSwitch = ({
  backgroundColor,
  color,
  buttonText,
  heroBtn,
  guideBtn,
  getStartedBtn,
  type,
  startIcon,
  loading,
  ...buttonProps
}) => {
  const CustomSwitch = styled(Switch)(({ theme }) => ({
    width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: '#5E17EB',
        opacity: 1,
        border: 0,
        
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color: theme.palette.grey[100],
      
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: 0.7,
      
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor: '#E9E9EA',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
    
  },
  }));

  return <CustomSwitch {...buttonProps} />
    ;
};

export default CustomSwitch;