import {
  Box,
  Button,
  Card,
  CardMedia,
  Link,
  Stack,
  styled,
  Typography,
} from "@mui/material";
import { Container } from "@mui/system";
import React from "react";
import Navbar from "./Navbar";

import heroImg from "../media/imageHero.jpg";
import CustomButton from "./CustomButton";

import Typewriter from "typewriter-effect";
import heroImage from "../media/imageHero2.jpg";
import heroImageBack from "../media/imageHeroback2.png";
import banner from "../media/banner.png";
import useResponsive from "./hooks/useResponsive";
import androidBadge from "../media/google-play-badge.png";
import iosBadge from "../media/ios.svg";

const Hero = () => {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");

  const CustomBox = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    gap: theme.spacing(5),
    marginTop: theme.spacing(7),
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const Title = styled(Typography)(({ theme }) => ({
    fontSize: "64px",
    color: "#000336",
    fontWeight: "bold",
    margin: theme.spacing(4, 0, 4, 0),
    [theme.breakpoints.down("sm")]: {
      fontSize: "40px",
    },
  }));

  return (
    <Box>
      {/*<Navbar />*/}
      <Box sx={{ paddingY: { xs: 10, sm: 10 }, minHeight: "50vh" }}>
        <Container>
          {
            /* 
            */
          }
          <CustomBox>
            <Box
              sx={{
                bgcolor: "black",
                position: "absolute",
                width: "100%",
                left: 0,
                zIndex: -1,
                filter: { xs: "blur(5rem)", sm: "blur(5rem)" },
              }}
            >
              {/** */}
              <CardMedia
                component="img"
                sx={{ width: "100%", objectFit: "cover" }}
                height="400"
                image={heroImageBack}
                title="green iguana"
              />
              <Box
                sx={{
                  bgcolor: "black",
                  width: "100%",
                  height: "400",
                }}
              />
            </Box>

            {/*
              smUp &&  <Box sx={{ flex: "1.25", borderRadius: "7px" }}>
              <img
                src={heroImage}
                alt="heroImg"
                style={{
                  maxWidth: "100%",
                  marginBottom: "2rem",
                  borderRadius: "10px",
                }}
              />
            </Box>
              */}
          </CustomBox>
          <Card sx={{ padding: 5, bgcolor: '#1F1F1F', color: 'white', borderRadius: '16px' }}>
            <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2} justifyContent='space-around'>
              <Box sx={{}}>
                <Typography textAlign='center'
                  sx={{ fontSize: "38px", fontWeight: "800" }}
                >
                  Prêt à vivre des expériences incroyables ?
                </Typography>
                <Typography
                  paddingTop={4}
                  variant="body2"
                  textAlign='center'
                  sx={{
                    fontSize: "16px",

                    fontWeight: "500",
                  }}
                >
                  Trouvez les billets que vous cherchez en quelques clics sur
                  notre plateforme.
                </Typography>
                <Typography
                  variant="body2" textAlign='center'
                  sx={{ fontSize: "16px", mt: 2, mb: 4 }}
                >
                  100% sécurisé et 100% fiable
                </Typography>

                <Stack direction="row" spacing={2} justifyContent="center" alignItems={'center'}>
                  <Link href="https://play.google.com/store/apps/details?id=com.choptonticket.choptonticket&hl=fr" target="_blank" rel="noopener noreferrer">
                    <img
                      src={androidBadge}
                      alt="Apple Store"
                      style={{ maxWidth: 130 }}
                    />
                  </Link>
                  <Link href="https://apps.apple.com/fr/app/choptonticket/id6736738261 " target="_blank" rel="noopener noreferrer">
                    <img style={{ Width: 148 }} src={iosBadge} alt="Google Play" />
                  </Link>

                </Stack>
              </Box>
              <Box>
                <CardMedia
                  component="img"
                  sx={{ width: "100%", height: { xs: '30%', sm: 400 }, objectFit: "cover", borderRadius: '10px' }}

                  image={heroImage}
                  title="green iguana"
                />
              </Box>
            </Stack>

          </Card>
        </Container>
      </Box>
    </Box>
  );
};

export default Hero;
