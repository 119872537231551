import * as React from "react";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import {
  CircularProgress,
  Container,
  Divider,
  Modal,
  Stack,
} from "@mui/material";
import {
  doc,
  getDoc,
} from "firebase/firestore";
import { db } from "../../firebase.config";
import { useParams } from "react-router-dom";
import ReserMobile from "../../Components/reservation/resMobile";
import { Variables } from "../../context/VariableContext";
import { UserAuth } from "../../context/AuthContext";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import "react-quill/dist/quill.bubble.css";
// import * as pay from "https://paydunya.com/assets/psr/js/psr.paydunya.min.js"


export default function EventPageEmbeded() {
  // quill rich text

  const { eventID } = useParams();
  const [loading, setLoading] = React.useState(false);
  const [event, setEvent] = React.useState();
  const [refreh, setRefresh] = React.useState(false);
  const { user } = UserAuth();
  const [isSticky, setSticky] = useState(0);
  const ref = useRef(null);
  const handleScroll = () => {
    if (ref.current) {
      setSticky(ref.current.getBoundingClientRect().top <= 0);
    }
  };

  useEffect(() => {
    console.log("ok");
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", () => handleScroll);
    };
  }, []);

  // Customization params
  const [bgColor, setBgColor] = useState("fffff");
  const [primaryColor, setPrimaryColor] = useState("5E17EB");
  const [secondaryColor, setSecondaryColor] = useState("999999");

  React.useEffect(() => {
    (async () => {
      console.log(
        "start____________________________________________________________"
      );
      try {
        setLoading(true);
        // const q = query(collection(db, `evenement/${eventID}/tickets`));
        const docRef = doc(db, "evenement", `${eventID}`);
        const docSnap = await getDoc(docRef);

        // console.log(docSnap.data());
        if (docSnap.exists()) {
          setEvent(docSnap.data());
        }
        

        // setLoading(false);
      } catch (error) {
        console.log(error);
      }
      setLoading(false);
    })();
  }, [refreh, user]);

 

  React.useEffect(() => {
    (async () => {
      setOpenContact(false);
      setOpenReservation(false);
    })();
  }, []);

  const [config, setConfig] = useState(null);

  useEffect(() => {
    const fetchConfig = async () => {
      const docRef = doc(db, "widgets", eventID);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        setConfig(docSnap.data());
      } else {
        console.error("No configuration found!");
      }
    };

    fetchConfig();
  }, [eventID]);


 
  // Modal
  const [open, setOpen] = React.useState(false);
  const handleClose = () => setOpen(false);

  // modal contact


  const {
    setOpenContact,
    setOpenReservation,
  } = Variables();

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    height: "100%",
    border: "px solid transparent",
    p: 2,
  };

  return (
    <>
      {event ? (
        <>
          <Container sx={{ paddingY: { xs: 6, sm: 10 } }}>
            <>
              <Box  paddingY={5}>

                <Modal
                  open={true}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={{...style, bgcolor:'white'}}>
                    <Stack sx={{}}>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          variant="h6"
                          fontWeight={500}
                          textAlign="center"
                          color={secondaryColor}
                        >
                          {event.nomEvent}
                        </Typography>

                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "center",
                          width: "100%",
                        }}
                      >
                        <Typography
                          fontStyle={10}
                          variant="caption"
                          textAlign="center"
                          color={`#${primaryColor}`}
                        >
                          generé par Choptonticket
                        </Typography>

                      </Box>
                    </Stack>
                    <Divider />
                    <ReserMobile eventID={eventID} widget={true}/>
                  </Box>
                </Modal>
              </Box>

            </>
          </Container>
        </>
      ) : (
        <Box height={'100%'} width={'100%'} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <CircularProgress sx={{ color: `#${primaryColor}` }} />
        </Box>

      )}
    </>
  );
}
