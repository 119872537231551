import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter } from 'react-router-dom';
import EnhancedCookieBanner from "./Components/cookieBanner/cookieBanner";
import UserLocation from "./Components/service/UserLocation";
import LocationProvider from "./Components/service/UserLocation";
import { SnackbarProvider } from "./context/snackbarContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <BrowserRouter>
        <SnackbarProvider>
            <LocationProvider>
                <EnhancedCookieBanner />
                <App />
            </LocationProvider>
        </SnackbarProvider>

    </BrowserRouter>
);
