import React, { createContext, useEffect, useState } from "react";
import Cookies from "js-cookie";

export const LocationContext = createContext();

const LocationProvider = ({ children }) => {
  const [location, setLocation] = useState(null);

  useEffect(() => {
    const fetchAndStoreLocation = async () => {

      const fetchedLocation = await fetchUserLocation();
      if (fetchedLocation) {
        setLocation(fetchedLocation);
        saveLocationToCookies(fetchedLocation);
      }

    };

    fetchAndStoreLocation();
  }, []);

  const fetchUserLocation = async () => {
    try {
      const response = await fetch("http://ip-api.com/json/"); 
      const data = await response.json();

      if (data.status === "success") {
        console.log(data)
        return {
          city: data.city,
          region: data.regionName,
          country: data.country,
          lat: data.lat,
          lon: data.lon,
        };
      } else {
        console.error("Error fetching location:", data.message);
        return null;
      }
    } catch (error) {
      console.error("Error fetching location:", error);
      return null;
    }
  };


  const saveLocationToCookies = (location) => {
    if (location) {
      Cookies.set("userLocation", JSON.stringify(location), { expires: 7 });
    }
  };

  const getLocationFromCookies = () => {
    const location = Cookies.get("userLocation");
    return location ? JSON.parse(location) : null;
  };

  return (
    <LocationContext.Provider value={location}>
      {children}
    </LocationContext.Provider>
  );
};

export default LocationProvider;
