import axios from "axios";

// Base URL de votre fonction Cloud
const BASE_URL = "https://us-central1-event-86cbf.cloudfunctions.net/getFilteredEvents";

// Fonction pour obtenir les événements filtrés


export const fetchFilteredEvents = async (filters, page) => {
    const baseUrl = BASE_URL; // Replace with your actual function URL
    const params = new URLSearchParams({
        search: filters?.search || null,
        location: filters?.location || null,
        price: filters?.price || null,
        date: filters?.date || null, // e.g., 'today', 'tomorrow', 'weekend'
        category: filters?.category || null,
        page: page || 1,
    });

    try {
        const response = await fetch('https://us-central1-event-86cbf.cloudfunctions.net/getFilteredEvents', {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          });
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response}`);
        }
        const data = await response.json();
        return data;
    } catch (error) {
        console.error("Error calling the Cloud Function:", error);
        return null;
    }
};

export const fetchFilteredEvents1 = async ({
    search = "",
    location = "",
    price,
    dateFilter = "",
    category = "",
    page = 1,
    limit = 10,
  }) => {
    try {
      const response = await axios.get(BASE_URL, {
        params: { search, location, price, dateFilter, category, page, limit },
      });
      return response.data; // Retourne les données du serveur
    } catch (error) {
      console.error("Erreur lors de la récupération des événements :", error);
      throw error;
    }
  };

  export const handleFirebaseTimestamp = (
    firebaseTimestamp
  ) => {
    try {
        
        const timestampInMillis = firebaseTimestamp._seconds * 1000 + firebaseTimestamp._nanoseconds / 1000000;
        const date = new Date(timestampInMillis);
      return date; // Retourne les données du serveur
    } catch (error) {
      console.error("Erreur lors de la récupération des événements :", error);
      throw error;
    }
  };