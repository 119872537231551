/* eslint-disable no-nested-ternary */
/* eslint-disable object-shorthand */
import { Box, Button, CircularProgress, Stack, styled, Typography } from "@mui/material";
import React from "react";
import Iconify from "./Iconify";
import { deleteDoc, doc, getDoc, setDoc, updateDoc } from "firebase/firestore";
import { db } from "../firebase.config";
import { UserAuth } from "../context/AuthContext";

const FollowButton = ({
    userId,
    event
}) => {
    const [loading, setLoading] = React.useState(false);
    const [isFollow, setIsFollow] = React.useState(false);
    const { user } = UserAuth();
    React.useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                const u1 = await getDoc(
                    doc(db, `users/${user.uid}/followings`, event.orgId)
                );
                if (u1.exists()) {
                    setIsFollow(true);
                } else {
                    setIsFollow(false);
                }
            } catch (error) {
                console.log(error.msg);
                setIsFollow(false);
            }
            setLoading(false);
        })();
    }, [event, user]);

    const follow = async () => {
        if (user) {
            var exist;
            console.log(user.email);
            setLoading(true);
            try {
                const u1 = await getDoc(
                    doc(db, `users/${user.uid}/followings`, event.orgId)
                );
                if (u1.exists()) {
                    exist = 1;
                } else {
                    exist = -1;
                }

                if (exist === -1) {
                    // abonnement
                    const u2 = await getDoc(
                        doc(
                            db,
                            `organisations/${event.orgId}/crm/${event.orgId}/contacts`,
                            user.email
                        )
                    );
                    if (u2.exists()) {
                        await updateDoc(
                            doc(
                                db,
                                `organisations/${event.orgId}/crm/${event.orgId}/contacts`,
                                user.email
                            ),
                            {
                                uid: user.uid,
                                // nom: infoUser.data().nom,
                                // prenom: infoUser.data().prenom,
                                push: true,
                                newslettres: true,
                            }
                        );
                    } else {
                        await setDoc(
                            doc(
                                db,
                                `organisations/${event.orgId}/crm/${event.orgId}/contacts`,
                                user.email
                            ),
                            {
                                uid: user.uid,
                                // nom: infoUser.data().nom,
                                // prenom: infoUser.data().prenom,
                                push: true,
                                newslettres: true,
                            }
                        );
                    }

                    await setDoc(doc(db, `users/${user.uid}/followings/${event.orgId}`), {
                        push: true,
                        newslettres: true,
                    });
                } else {
                    // desabonnement
                    await updateDoc(
                        doc(
                            db,
                            `organisations/${event.orgId}/crm/${event.orgId}/contacts`,
                            user.email
                        ),
                        {
                            uid: user.uid,
                            // nom: infoUser.data().nom,
                            // prenom: infoUser.data().prenom,
                            push: false,
                            newslettres: false,
                        }
                    );

                    await deleteDoc(doc(db, `users/${user.uid}/followings`, event.orgId));
                }

                // setConfirm(true);
            } catch (error) {
                console.log(error);
            }
            setLoading(false);
            // setRefresh(!refreh);
        } else {
            // setOpenLogin(true);
        }
    };


    return <Box>
        {
            loading ?
                <Button sx={{
                    paddingX: 2,
                    textTransform: 'initial',
                    border: '2px solid #1F1F1F',
                    color: '#1F1F1F',
                    backgroundColor: 'transparent',
                    borderRadius: '7px',
                    boxShadow: 'none',
                    '&:hover': {
                        backgroundColor: '#A47CF4',
                        color: 'white',
                        borderColor: '#A47CF4',
                    },
                }}>
                    <CircularProgress sx={{ color: '#5E17EB', width: 30, height: 30 }} />
                </Button> : isFollow ?
                    <Button sx={{
                        textTransform: 'initial',
                        border: '2px solid #1F1F1F',
                        color: 'white',
                        backgroundColor: '#1F1F1F',
                        borderRadius: '7px',
                        boxShadow: 'none',
                        '&:hover': {
                            border: '2px solid #1F1F1F',
                            color: 'white',
                            backgroundColor: '#1F1F1F',
                        },
                    }}>
                        <Stack
                            direction="row"
                            display="flex"
                            alignItems="center"
                            spacing={1}
                        >
                            <Typography variant="body2">Abonné.e</Typography>
                            <Iconify
                                icon="flat-color-icons:ok"
                                sx={{ height: 20, width: 20 }}
                            />
                        </Stack>
                    </Button> :
                    <Button disabled={loading} sx={{
                        textTransform: 'initial',
                        border: '2px solid #1F1F1F',
                        color: '#1F1F1F',
                        backgroundColor: 'transparent',
                        borderRadius: '7px',
                        boxShadow: 'none',
                        '&:hover': {
                            backgroundColor: '#A47CF4',
                            color: 'white',
                            borderColor: '#A47CF4',
                        },
                    }}>
                        <Typography variant="body2">S'abonner</Typography>
                    </Button>
        }
    </Box>;
};

export default FollowButton;