// cookieConfig.js
export const cookieCategories = {
    essential: {
      name: "Cookies Essentiels",
      description: "contribuent à rendre un site web utilisable en activant des fonctions de base comme la navigation de page et l'accès aux zones sécurisées du site web. Le site web ne peut pas fonctionner correctement sans ces cookies.",
      default: true, // Toujours activés
    },
    statistics: {
      name: "Cookies Statistiques",
      description: "aident les propriétaires du site web, par la collecte et la communication d'informations de manière anonyme, à comprendre comment les visiteurs interagissent avec les sites web.",
      default: false,
    },
    preferences: {
      name: "Cookies de Préférences",
      description: "permettent à un site web de retenir des informations qui modifient la manière dont le site se comporte ou s’affiche, comme votre langue préférée ou la région dans laquelle vous vous situez.",
      default: false,
    },
    marketing: {
      name: "Cookies Marketing",
      description: "utilisés pour effectuer le suivi des visiteurs au travers des sites web. Le but est d'afficher des publicités qui sont pertinentes et intéressantes pour l'utilisateur individuel et donc plus précieuses pour les éditeurs et annonceurs tiers.",
      default: false,
    },
  };
  