import React, { createContext, useState, useContext } from "react";
import { Snackbar, Alert, Stack, Typography, Box } from "@mui/material";
import Iconify from "../Components/Iconify";

const SnackbarContext = createContext();

export const SnackbarProvider = ({ children }) => {
  const [snackbar, setSnackbar] = useState({
    open: false,
    msg: "",
    severity: "info", // Options: "error", "warning", "info", "success"
  });

  const showSnackbar = (msg, severity = "info") => {
    setSnackbar({ open: true, msg, severity });
  };

  const closeSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <SnackbarContext.Provider value={showSnackbar}>
      {children}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={3000}
        onClose={closeSnackbar}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        message={
            <Box sx={{width:'100%', display: 'flex', alignItems: 'center', justifyContent:'space-between' }}>
                {snackbar.msg}{' '}
                <Box paddingX={1}/>
                <Iconify
                    sx={{ color: snackbar.severity === 'success' ? 'green' : 'red', height: 20, width: 20 }}
                    icon={`${snackbar.severity === 'success' ? 'mdi:success-circle-outline' : 'system-uicons:cross-circle'}`}
                />
            </Box>
          }
      /> 
    </SnackbarContext.Provider>
  );
};

export const useSnackbar = () => {
  return useContext(SnackbarContext);
};