import {
  Backdrop,
  Box,
  Button,
  Card,
  Divider,
  Fade,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link,
  Modal,
  Radio,
  RadioGroup,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useLoadScript } from "@react-google-maps/api";
import {
  collection,
  doc,
  endBefore,
  getCountFromServer,
  getDoc,
  getDocs,
  limit,
  limitToLast,
  or,
  orderBy,
  query,
  startAfter,
  where,
} from "firebase/firestore";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import useResponsive from "../Components/hooks/useResponsive";
import Iconify from "../Components/Iconify";
import ResearchItem from "../Components/researchItem";
import { db } from "../firebase.config";
import ResearchItemLoading from "../Components/Research/ResearchItemLoading";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import Paginate from "../Components/paginate";
import MapsSearch from "../Components/map/MapsClustering";
import CustomButton from "../Components/CustomButton";
import { fetchFilteredEvents, fetchFilteredEvents1 } from "../Components/api/api";



const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  p: 2,
  height: "100%",
  width: "100%",
};

const SearchPage = () => {
  const smUp = useResponsive("up", "sm");

  const mdUp = useResponsive("up", "md");
  const { city, filtres, name } = useParams();
  const [types, setTypes] = React.useState([
    {
      id: "concert",
      nom: "Concert",
      icon: "material-symbols:music-note-rounded",
    },
    { id: "atelier", nom: "Atelier", icon: "grommet-icons:workshop" },
    { id: "soirée gala", nom: "Soirée clubbing", icon: "" },
    { id: "festival", nom: "Festival", icon: "" },
  ])

  const [cat, setCat] = React.useState(["Concert", "Gala", "Festival", "Atelier"])
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [events, setEvents] = React.useState([]);
  const [orgs, setOrgs] = React.useState([]);
  const [filtreValue, setFiltreValue] = React.useState([]);
  const [dateFilter, setDateFilter] = React.useState("");
  const [prixFilter, setPrixFilter] = React.useState("");
  const [catFilter, setCatFilter] = React.useState("");
  const [cityField, setCityField] = React.useState("");
  const [eventField, setEventField] = React.useState("");
  const [hoverE, setHover] = React.useState();

  //pagination
  const [queryList, setQuery] = React.useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const [totalItems, setTotalItems] = useState(0);
  const [totalCount, setTotalCount] = React.useState();
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);

  const getMoreType = async () => {

    const q = query(
      collection(db, "types"),
    );
    const typeSnap = await getDocs(q);
    console.log(typeSnap)
    setTypes([])
    typeSnap.forEach(element => {
      setTypes((types) => [...types, { 'id': element.id, 'nom': element.data()['nom'] }]);
      setCat((cats) => [...cats, element.data()['nom']]);
    });
  }

  const getLessType = async () => {

    const q = query(
      collection(db, "types"), limit(4)
    );
    const typeSnap = await getDocs(q);
    console.log(typeSnap)
    setTypes([])
    typeSnap.forEach(element => {
      setTypes((types) => [...types, { 'id': element.id, 'nom': element.data()['nom'] }]);
      setCat((cats) => [...cats, element.data()['nom']]);
    });
  }

  const showNext = () => {
    console.log(totalPage)
    if (page === totalPage) {
      console.log("Thats all we have for now !");
    } else {
      setCurrentPage(page + 1)
      setPage(page + 1)
    }
  };

  const showPrevious = (item) => {
    if (page === 1) {
      console.log('impossible')
    } else {
      setCurrentPage(page - 1)
      setPage(page - 1)
    }
  };

  const handleTime = (e) => {
    const date = e.toDate();
    const tmp = date.toJSON().split('.');
    return tmp[0];
  };

  const changeDate = (e) => {
    setDateFilter(e.target.value);
    navigate(
      `/fr/${city}/${prixFilter === "" ? "" : `${prixFilter}--`}${catFilter === "" ? "" : `${catFilter}--`
      }events--${e.target.value}`
    );
  };

  const changePrix = (e) => {
    setPrixFilter(e.target.value);
    navigate(
      `/fr/${city}/${e.target.value}--${catFilter === "" ? "" : `${catFilter}--`
      }events${dateFilter === "" ? "" : `--${dateFilter}`}`
    );
  };
  const changeCat = (e) => {
    setCatFilter(e.target.value);
    navigate(
      `/fr/${city}/${prixFilter === "" ? "" : `${prixFilter}--`}${e.target.value
      }--events${dateFilter === "" ? "" : `--${dateFilter}`}`
    );
  };
  const removeCatFilter = (e) => {
    setCatFilter("");
    if (dateFilter === "" && prixFilter === "") {
      navigate(`/fr/${city}/all--event`);
    } else {
      navigate(
        `/fr/${city}/${prixFilter === "" ? "" : `${prixFilter}--`}events${dateFilter === "" ? "" : `--${dateFilter}`
        }`
      );
    }
  };
  const removePrixFilter = (e) => {
    setPrixFilter("");
    if (dateFilter === "" && catFilter === "") {
      navigate(`/fr/${city}/all--event`);
    } else {
      navigate(
        `/fr/${city}/${catFilter === "" ? "" : `${catFilter}--`}events${dateFilter === "" ? "" : `--${dateFilter}`
        }`
      );
    }
  };
  const removeDateFilter = (e) => {
    setDateFilter("");
    if (prixFilter === "" && catFilter === "") {
      navigate(`/fr/${city}/all--event`);
    } else {
      navigate(
        `/fr/${city}/${prixFilter === "" ? "" : `${prixFilter}--`}${catFilter === "" ? "" : `${catFilter}--`
        }events`
      );
    }
  };
  const [center, setCenter] = React.useState();
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // google place auto
  const libraries = ["places"];
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_MAP_API_KEY,
    libraries,
  });
  const searchOptions = {
    componentRestrictions: { country: "CIV" },
    fields: ["address_components", "geometry", "icon", "name"],
    types: ["locality"],
  };
  const handleSelect = async (address, placeId, suggestion) => {
    console.log(suggestion.description);
    console.log(address);
    console.log(placeId);
    const tmp = suggestion.description.split(",");
    setCityField(tmp[0]);
    const results = await geocodeByAddress(address);
    const ll = await getLatLng(results[0]);
    console.log(results);
    console.log(ll);
    setCenter(ll);
    if (filtres === "all--event") {
      navigate(`/fr/${tmp[1]}--${tmp[0]}/all--event`);
    } else {
      navigate(
        `/fr/${tmp[1]}--${tmp[0]}/${prixFilter === "" ? "" : `${prixFilter}--`
        }${catFilter === "" ? "" : `${catFilter}--`}events${dateFilter === "" ? "" : `--${dateFilter}`
        }`
      );
    }
  };

  const loadEvents = async (filter) => {
    setLoading(true);
    // setError(null); // Réinitialisation de l'erreur
    setEvents([]); // Réinitialisation des événements
    const tmpfilter = filter || {
      search: eventField,
      dateFilter,
      category: catFilter,
      location: cityField,
      page: currentPage,
    }
    try {
      console.log(eventField);
  
      // Récupérer les événements filtrés
      const data = await fetchFilteredEvents1(tmpfilter);
  
      console.log(data);
  
      // Récupérer les organisations en parallèle pour chaque événement
      const eventsWithOrg = await Promise.all(
        data.events.map(async (d) => {
          const orgDoc = await getDoc(doc(db, "organisations", d.data.orgId));
          return {
            infos: { id: d.id, data: d.data },
            org: orgDoc.data()?.nomOrg || "Inconnu", // Gestion des organisations manquantes
          };
        })
      );
  
      setEvents(eventsWithOrg); // Mettre à jour les événements
      setTotalCount(Math.ceil(data.total / itemsPerPage));
      setTotalItems(data.total);
      setTotalPage(Math.ceil(data.total / itemsPerPage));
    } catch (err) {
      console.error(`Impossible de charger les événements : ${err}`);
    } finally {
      setLoading(false);
    }
  };
  
  React.useEffect(() => {
    (async () => {
      setLoading(true);
      setEvents([]); // Nettoyage des états pour indiquer le chargement
  
      try {
        console.log(city, filtres, name);
  
        if (city === "all--city" && filtres === "all--event") {
          // Aucun filtre spécifique, charger tous les événements
          await loadEvents({ search: "", dateFilter: "", category: "", location: "", page: currentPage });
          return;
        }
  
        // Analyser et appliquer les filtres
        const AllFilter = filtres.split("--");
        const cityFilter = city.split("--");
  
        const priceFilters = ["paid", "free"];
        const dateFilters = ["today", "tomorrow", "weekend"];
  
        // Calculer les filtres dynamiquement
        setPrixFilter(priceFilters.find((filter) => AllFilter.includes(filter)) || "");
        setCatFilter(cat.find((filter) => AllFilter.includes(filter)) || "");
        setDateFilter(dateFilters.find((filter) => AllFilter.includes(filter)) || "");
        const searchField = name || "";
        const locationField = city !== "all--city" ? cityFilter[1] : "";
        const priceField = priceFilters.find((filter) => AllFilter.includes(filter)) || "";
        const categoryField = cat.find((filter) => AllFilter.includes(filter)) || "";
        const dateField = dateFilters.find((filter) => AllFilter.includes(filter)) || "";
  
        // Charger les événements avec les filtres calculés
        await loadEvents({
          search: searchField,
          dateFilter: dateField,
          category: categoryField,
          location: locationField,
          page: currentPage,
        });
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    })();
  }, [filtres, city, currentPage]);
  


  return (
    <Box sx={{ paddingY: { xs: 10, sm: 10 } }}>
      <Grid paddingBottom={5} container spacing={2}>
        <Grid item xs={12} md={7}>
          <Grid container paddingX={2}>
            {/*** Textfield recherche */}
            <Grid item xs={12} paddingTop={2}>
              <Stack direction="row" spacing={2} padding={1} sx={{ bgcolor: '#f2f2f2', borderRadius: '10px' }}>
                <Stack direction="column" sx={{ width: "100%" }}>
                  <TextField
                    variant="outlined"
                    placeholder="Rechercher vos evenement"
                    sx={{
                      "& label.Mui-focused": {
                        color: "transparent",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "transparent",
                      },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": {
                          borderColor: "transparent",
                        },
                        "&:hover fieldset": {
                          borderColor: "transparent",
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: "transparent",
                        },
                      },
                    }}
                    value={eventField}
                    onChange={(e) => setEventField(e.target.value)}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <Iconify
                            icon="material-symbols:search-rounded"
                            sx={{ width: 30, height: 30 }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                  {isLoaded && (
                    <PlacesAutocomplete
                      value={cityField}
                      onChange={setCityField}
                      onSelect={handleSelect}
                      searchOptions={searchOptions}
                      googleCallbackName=""
                    >
                      {({
                        getInputProps,
                        suggestions,
                        getSuggestionItemProps,
                        loading,
                      }) => (
                        <div>
                          <TextField
                            fullWidth
                            {...getInputProps({
                              placeholder: "Choisir une location",
                            })}
                            variant="outlined"
                            sx={{
                              "& label.Mui-focused": {
                                color: "transparent",
                              },
                              "& .MuiInput-underline:after": {
                                borderBottomColor: "transparent",
                              },
                              "& .MuiOutlinedInput-root": {
                                "& fieldset": {
                                  borderColor: "transparent",
                                },
                                "&:hover fieldset": {
                                  borderColor: "transparent",
                                },
                                "&.Mui-focused fieldset": {
                                  borderColor: "transparent",
                                },
                              },
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Iconify
                                    icon="material-symbols:location-on-outline"
                                    sx={{ width: 30, height: 30 }}
                                  />
                                </InputAdornment>
                              ),
                              endAdornment: (
                                <InputAdornment position="end">
                                  {cityField !== "" && (
                                    <IconButton
                                      onClick={() => {
                                        if (filtres === "all--event") {
                                          navigate(`/fr/all--city/all--event`);
                                        } else {
                                          navigate(
                                            `/fr/all--city/${prixFilter === ""
                                              ? ""
                                              : `${prixFilter}--`
                                            }${catFilter === ""
                                              ? ""
                                              : `${catFilter}--`
                                            }events${dateFilter === ""
                                              ? ""
                                              : `--${dateFilter}`
                                            }`
                                          );
                                        }
                                      }}
                                    >
                                      <Iconify
                                        icon="material-symbols:close-rounded"
                                        sx={{ width: 24, height: 24 }}
                                      />
                                    </IconButton>
                                  )}
                                </InputAdornment>
                              ),
                            }}
                          />

                          <Card>
                            {loading && <div>Loading...</div>}
                            {suggestions.map((suggestion) => {
                              const className = suggestion.active
                                ? "suggestion-item--active"
                                : "suggestion-item";
                              // inline style for demonstration purpose
                              const style = suggestion.active
                                ? {
                                  backgroundColor: "#fafafa",
                                  cursor: "pointer",
                                  padding: 2,
                                }
                                : {
                                  backgroundColor: "#ffffff",
                                  cursor: "pointer",
                                  padding: 2,
                                };
                              return (
                                <Box
                                  paddingX={2}
                                  key={suggestion.placeId}
                                  {...getSuggestionItemProps(suggestion, {
                                    className,
                                    style,
                                  })}
                                >
                                  <Typography fontSize={16}>
                                    {suggestion.description}
                                  </Typography>
                                </Box>
                              );
                            })}
                          </Card>
                        </div>
                      )}
                    </PlacesAutocomplete>
                  )}
                </Stack>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "center",
                  }}
                >
                  {!smUp ? (
                    <Box sx={{ borderRadius: "50px", bgcolor: "#f5f5f5" }}>
                      <IconButton aria-label="search" size="large">
                        <Iconify
                          icon="material-symbols:search-rounded"
                          sx={{ width: 24, height: 24 }}
                        />
                      </IconButton>
                    </Box>
                  ) : (
                    <Button
                      size="large"
                      sx={{
                        textTransform: "initial",
                        border: "2px solid transparent",
                        color: "white",
                        backgroundColor: "#5E17EB",
                        borderRadius: "7px",
                        "&:hover": {
                          backgroundColor: "white",
                          color: "#5E17EB",
                          borderColor: "#5E17EB",
                        },
                      }}
                      variant="contained"
                      onClick={loadEvents}
                    >
                      Recherche
                    </Button>
                  )}
                </Box>
              </Stack>
              <Divider />
            </Grid>
            {/** event + filtres */}
            <Grid item xs={12} paddingY={3}>
              <Grid container>
                {/** filtres */}
                {smUp && mdUp && (
                  <Grid item xs={3} >
                    <Typography variant="h5" fontWeight={600}>
                      Filtres
                    </Typography>
                    <Stack padding={2} spacing={2}>
                      <FormControl
                        component="fieldset"
                        variant="standard"
                      >
                        <FormLabel component="legend">Date</FormLabel>
                        <RadioGroup
                          value={dateFilter}
                          onChange={(e) => changeDate(e)}
                        >
                          <FormControlLabel
                            value="today"
                            control={<Radio />}
                            label={
                              <Typography fontSize={14}>Aujourd'hui</Typography>
                            }
                          />
                          <FormControlLabel
                            value="tomorrow"
                            control={<Radio />}
                            label={
                              <Typography fontSize={14}>Demain</Typography>
                            }
                          />
                          <FormControlLabel
                            value="weekend"
                            control={<Radio />}
                            label={
                              <Typography fontSize={14}>Ce Weekend</Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                      {/** price filter */}
                      <FormControl
                        component="fieldset"
                        variant="standard"
                      >
                        <FormLabel component="legend">Prix</FormLabel>
                        <RadioGroup
                          value={prixFilter}
                          onChange={(e) => changePrix(e)}
                        >
                          <FormControlLabel
                            value="free"
                            control={<Radio />}
                            label={
                              <Typography fontSize={14}>Gratuit</Typography>
                            }
                          />
                          <FormControlLabel
                            value="paid"
                            control={<Radio />}
                            label={
                              <Typography fontSize={14}>Payant</Typography>
                            }
                          />
                        </RadioGroup>
                      </FormControl>
                      {/** categorie filtre */}
                      <FormControl
                        component="fieldset"
                        variant="standard"
                      >
                        <FormLabel component="legend">Categories</FormLabel>
                        <RadioGroup
                          value={catFilter}
                          onChange={(e) => changeCat(e)}
                        >
                          {types.map((type) => (
                            <FormControlLabel
                              value={type.nom}
                              control={<Radio />}
                              label={
                                <Typography fontSize={14}>
                                  {type.nom}
                                </Typography>
                              }
                            />
                          ))}
                        </RadioGroup>
                        {
                          types.length > 4 ? <Link sx={{ cursor: 'pointer' }} fontSize={14} onClick={() => { getLessType() }}>afficher moins</Link> : <Link sx={{ cursor: 'pointer' }} fontSize={14} onClick={() => { getMoreType() }}>afficher plus</Link>
                        }

                      </FormControl>
                    </Stack>
                  </Grid>
                )}

                <Grid paddingY={{ xs: 0, sm: 2 }} item xs={12} md={9}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} sx={{ position: "sticky" }}>
                      <Stack
                        direction="row"
                        paddingX={{ xs: 1, sm: 3 }}
                        sx={{ overflowX: "auto", width: "100%" }}
                      >
                        {!smUp && !mdUp && (
                          <Grid item xs={5} >
                            {dateFilter !== "" ||
                              catFilter !== "" ||
                              prixFilter !== "" ? (
                              <Box
                                sx={{

                                  color: "white",
                                  border: " 2px solid #f5f5f5",
                                  bgcolor: "#5E17EB",
                                  borderRadius: "20px",
                                  marginX: 1,
                                  "&:hover": {
                                    cursor: "pointer",
                                    borderColor: "#5E17EB",
                                  },
                                }}
                              >
                                <Stack
                                  onClick={() => {
                                    handleOpen();
                                  }}
                                  direction="row"
                                  display="flex"
                                  alignItems="center"
                                  spacing={1}
                                  paddingX={2}
                                  paddingY={1}
                                >
                                  <Iconify
                                    icon="system-uicons:filtering"
                                    sx={{ width: 24, height: 24 }}
                                  />
                                  <Typography variant="body1">
                                    Fitres
                                  </Typography>
                                </Stack>
                              </Box>
                            ) : (
                              <Box
                                sx={{
                                  marginY: 1,
                                  border: " 1px solid #f5f5f5",
                                  bgcolor: "#f5f5f5",
                                  borderRadius: "20px",
                                  marginX: 1,
                                  "&:hover": {
                                    cursor: "pointer",
                                    borderColor: "#5E17EB",
                                  },
                                }}
                              >
                                <Stack
                                  onClick={() => {
                                    handleOpen();
                                  }}
                                  direction="row"
                                  display="flex"
                                  alignItems="center"
                                  spacing={1}
                                  paddingX={2}
                                  paddingY={1}
                                >
                                  <Iconify
                                    icon="system-uicons:filtering"
                                    sx={{ width: 24, height: 24 }}
                                  />
                                  <Typography variant="body1">
                                    Fitres
                                  </Typography>
                                </Stack>
                              </Box>
                            )}
                          </Grid>
                        )}
                        {/** Filtre Modal */}
                        <Modal
                          aria-labelledby="transition-modal-title"
                          aria-describedby="transition-modal-description"
                          open={open}
                          onClose={handleClose}
                          closeAfterTransition
                          slots={{ backdrop: Backdrop }}
                          slotProps={{
                            backdrop: {
                              timeout: 500,
                            },
                          }}
                        >
                          <Fade in={open}>
                            <Box sx={style}>
                              <Grid item xs={12} sx={{ overflowX: "auto", overflowY: "auto", height: '90%' }}>
                                <Card
                                  elevation={0}
                                  sx={{
                                    position: "fixed",
                                    bgcolor: '#f2f2f2',
                                    top: 0,
                                    left: 0,
                                    right: 0,
                                    zIndex: 9999,
                                    paddingX: 2,
                                    paddingY: 2,

                                  }}
                                >
                                  <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                  >
                                    <Typography variant="h5" fontWeight={600}>
                                      Filtres
                                    </Typography>
                                    <IconButton
                                      aria-label="search"
                                      size="large"
                                      onClick={handleClose}
                                    >
                                      <Iconify
                                        icon="ri:arrow-drop-down-line"
                                        sx={{ width: 24, height: 24 }}
                                      />
                                    </IconButton>
                                  </Stack>
                                  <Stack direction={'row'} sx={{ overflowX: "auto", overflowY: "auto", height: '100%' }}>
                                    {dateFilter !== "" && (
                                      <Box
                                        sx={{
                                          border: " 1px solid #f5f5f5",
                                          bgcolor: "#f5f5f5",
                                          borderRadius: "20px",
                                          marginX: 1,
                                          "&:hover": {
                                            cursor: "pointer",
                                            borderColor: "#5E17EB",
                                          },
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          display="flex"
                                          alignItems="center"
                                          spacing={1}
                                          paddingX={2}
                                          paddingY={1}
                                        >
                                          <Typography
                                            noWrap
                                            sx={{ textOverflow: "ellipsis", width: "100%" }}
                                            variant="caption"
                                          >
                                            {dateFilter === "today"
                                              ? "Aujourd'hui"
                                              : dateFilter === "tomorrow"
                                                ? "Demain"
                                                : dateFilter === "weekend"
                                                  ? "Ce weekend"
                                                  : null}
                                          </Typography>
                                          <Iconify
                                            icon="material-symbols:close-rounded"
                                            onClick={() => removeDateFilter()}
                                          />
                                        </Stack>
                                      </Box>
                                    )}
                                    {prixFilter !== "" && (
                                      <Box
                                        sx={{
                                          border: " 1px solid #f5f5f5",
                                          bgcolor: "#f5f5f5",
                                          borderRadius: "20px",
                                          marginX: 1,
                                          "&:hover": {
                                            cursor: "pointer",
                                            borderColor: "#5E17EB",
                                          },
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          display="flex"
                                          alignItems="center"
                                          spacing={1}
                                          paddingX={2}
                                          paddingY={1}
                                        >
                                          <Typography
                                            noWrap
                                            sx={{ textOverflow: "ellipsis", width: "100%" }}
                                            variant="caption"
                                          >
                                            {prixFilter === "paid"
                                              ? "Payant"
                                              : prixFilter === "free"
                                                ? "Gratuit"
                                                : null}
                                          </Typography>
                                          <Iconify
                                            icon="material-symbols:close-rounded"
                                            onClick={() => removePrixFilter()}
                                          />
                                        </Stack>
                                      </Box>
                                    )}
                                    {catFilter !== "" && (
                                      <Box
                                        sx={{
                                          border: " 1px solid #f5f5f5",
                                          bgcolor: "#f5f5f5",
                                          borderRadius: "20px",
                                          marginX: 1,
                                          "&:hover": {
                                            cursor: "pointer",
                                            borderColor: "#5E17EB",
                                          },
                                        }}
                                      >
                                        <Stack
                                          direction="row"
                                          display="flex"
                                          alignItems="center"
                                          spacing={1}
                                          paddingX={2}
                                          paddingY={1}
                                        >
                                          <Typography
                                            noWrap
                                            sx={{ textOverflow: "ellipsis", width: "100%" }}
                                            variant="caption"
                                          >
                                            {catFilter}
                                          </Typography>
                                          <Iconify
                                            icon="material-symbols:close-rounded"
                                            onClick={() => removeCatFilter()}
                                          />
                                        </Stack>
                                      </Box>
                                    )}
                                    {(prixFilter !== "" ||
                                      dateFilter !== "" ||
                                      catFilter !== "") && (
                                        <Box sx={{ display: "flex", alignItems: "center" }}>
                                          <Typography variant="caption">
                                            Tout Annuler
                                          </Typography>
                                        </Box>
                                      )}
                                  </Stack>
                                </Card>
                                <Stack paddingTop={10}>
                                  <FormControl
                                    sx={{ mt: 3 }}
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormLabel component="legend">
                                      Date
                                    </FormLabel>
                                    <RadioGroup
                                      value={dateFilter}
                                      onChange={(e) => changeDate(e)}
                                    >
                                      <FormControlLabel
                                        value="today"
                                        control={<Radio />}
                                        label={
                                          <Typography fontSize={14}>
                                            Aujourd'hui
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        value="tomorrow"
                                        control={<Radio />}
                                        label={
                                          <Typography fontSize={14}>
                                            Demain
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        value="weekend"
                                        control={<Radio />}
                                        label={
                                          <Typography fontSize={14}>
                                            Ce Weekend
                                          </Typography>
                                        }
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Stack>
                                {/** price filter */}
                                <Stack>
                                  <FormControl
                                    sx={{ mt: 3 }}
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormLabel component="legend">
                                      Prix
                                    </FormLabel>
                                    <RadioGroup
                                      value={prixFilter}
                                      onChange={(e) => changePrix(e)}
                                    >
                                      <FormControlLabel
                                        value="free"
                                        control={<Radio />}
                                        label={
                                          <Typography fontSize={14}>
                                            Gratuit
                                          </Typography>
                                        }
                                      />
                                      <FormControlLabel
                                        value="paid"
                                        control={<Radio />}
                                        label={
                                          <Typography fontSize={14}>
                                            Payant
                                          </Typography>
                                        }
                                      />
                                    </RadioGroup>
                                  </FormControl>
                                </Stack>
                                {/** categorie filtre */}
                                <Stack paddingBottom={2}>
                                  <FormControl
                                    sx={{ mt: 3 }}
                                    component="fieldset"
                                    variant="standard"
                                  >
                                    <FormLabel >
                                      <Stack direction={'row'} justifyContent={'space-between'}>
                                        <Typography>Categories</Typography>
                                        {
                                          types.length > 4 ? <Link sx={{ cursor: 'pointer' }} fontSize={14} onClick={() => { getLessType() }}>afficher moins</Link> : <Link sx={{ cursor: 'pointer' }} fontSize={14} onClick={() => { getMoreType() }}>afficher plus</Link>
                                        }
                                      </Stack>
                                    </FormLabel>
                                    <RadioGroup
                                      value={catFilter}
                                      onChange={(e) => changeCat(e)}
                                    >
                                      {types.map((type) => (
                                        <FormControlLabel
                                          value={type.id}
                                          control={<Radio />}
                                          label={
                                            <Typography fontSize={14}>
                                              {type.nom}
                                            </Typography>
                                          }
                                        />
                                      ))}
                                    </RadioGroup>
                                  </FormControl>
                                </Stack>
                              </Grid>
                              <Card
                                elevation={0}
                                sx={{
                                  position: "fixed",
                                  bottom: 0,
                                  left: 0,
                                  right: 0,
                                  paddingX: 3,
                                  paddingY: 2,
                                  borderTop: '2px solid #f2f2f2'
                                }}
                              >
                                <CustomButton buttonText={'Afficher les resultats'} type={'primaire'} onClick={handleClose} fullWidth />
                              </Card>
                            </Box>
                          </Fade>
                        </Modal>
                        {dateFilter !== "" && (
                          <Box
                            sx={{
                              border: " 1px solid #f5f5f5",
                              bgcolor: "#f5f5f5",
                              borderRadius: "20px",
                              marginX: 1,
                              "&:hover": {
                                cursor: "pointer",
                                borderColor: "#5E17EB",
                              },
                            }}
                          >
                            <Stack
                              direction="row"
                              display="flex"
                              alignItems="center"
                              spacing={1}
                              paddingX={2}
                              paddingY={1}
                            >
                              <Typography
                                noWrap
                                sx={{ textOverflow: "ellipsis", width: "100%" }}
                                variant="caption"
                              >
                                {dateFilter === "today"
                                  ? "Aujourd'hui"
                                  : dateFilter === "tomorrow"
                                    ? "Demain"
                                    : dateFilter === "weekend"
                                      ? "Ce weekend"
                                      : null}
                              </Typography>
                              <Iconify
                                icon="material-symbols:close-rounded"
                                onClick={() => removeDateFilter()}
                              />
                            </Stack>
                          </Box>
                        )}
                        {prixFilter !== "" && (
                          <Box
                            sx={{
                              border: " 1px solid #f5f5f5",
                              bgcolor: "#f5f5f5",
                              borderRadius: "20px",
                              marginX: 1,
                              "&:hover": {
                                cursor: "pointer",
                                borderColor: "#5E17EB",
                              },
                            }}
                          >
                            <Stack
                              direction="row"
                              display="flex"
                              alignItems="center"
                              spacing={1}
                              paddingX={2}
                              paddingY={1}
                            >
                              <Typography
                                noWrap
                                sx={{ textOverflow: "ellipsis", width: "100%" }}
                                variant="caption"
                              >
                                {prixFilter === "paid"
                                  ? "Payant"
                                  : prixFilter === "free"
                                    ? "Gratuit"
                                    : null}
                              </Typography>
                              <Iconify
                                icon="material-symbols:close-rounded"
                                onClick={() => removePrixFilter()}
                              />
                            </Stack>
                          </Box>
                        )}
                        {catFilter !== "" && (
                          <Box
                            sx={{
                              border: " 1px solid #f5f5f5",
                              bgcolor: "#f5f5f5",
                              borderRadius: "20px",
                              marginX: 1,
                              "&:hover": {
                                cursor: "pointer",
                                borderColor: "#5E17EB",
                              },
                            }}
                          >
                            <Stack
                              direction="row"
                              display="flex"
                              alignItems="center"
                              spacing={1}
                              paddingX={2}
                              paddingY={1}
                            >
                              <Typography
                                noWrap
                                sx={{ textOverflow: "ellipsis", width: "100%" }}
                                variant="caption"
                              >
                                {catFilter}
                              </Typography>
                              <Iconify
                                icon="material-symbols:close-rounded"
                                onClick={() => removeCatFilter()}
                              />
                            </Stack>
                          </Box>
                        )}
                        {(prixFilter !== "" ||
                          dateFilter !== "" ||
                          catFilter !== "") && (
                            <Box sx={{ display: "flex", alignItems: "center" }}>
                              <Typography variant="caption">
                                Tout Annuler
                              </Typography>
                            </Box>
                          )}
                      </Stack>
                    </Grid>

                    {/**affichage de la liste des event */}
                    {loading ? (
                      <ResearchItemLoading />
                    ) : (
                      <>
                        {events.map((property, index) => (
                          <Grid item xs={12} sx={{ height: `100%` }}>
                            <Box
                              onClick={() => {
                                navigate(`/fr/event/${property.infos.id}`);
                              }}
                              onMouseEnter={() => {
                                setHover(property.infos.data.localisation);
                              }}
                            >
                              <ResearchItem
                                fin={property.infos.data.dateFin}
                                seanceActive={
                                  property.infos.data.seanceActive
                                }
                                key={property.infos.id}
                                ev={property.infos}
                                img={property.infos.data.idVisuel}
                                name={property.infos.data.nomEvent}
                                lieu={property.infos.data.lieu_event}
                                city={property.infos.data.city}
                                type={property.infos.data.type}
                                debut={property.infos.data.dateDebut}
                                organisateur={property.org}
                                eventID={property.infos.id}
                                sx={{ height: `100%` }}
                              />
                            </Box>
                          </Grid>
                        ))}
                      </>
                    )}
                  </Grid>
                  {!loading && events.length === 0 && (
                    <Grid paddingY={5} item xs={12} md={9}>
                      <Stack
                        spacing={3}
                        direction="row"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        <Typography variant="h7" fontWeight={600}>
                          Rien ne correspond à votre recherche, mais vous
                          pourriez être intéressé d'autres événements.
                        </Typography>
                      </Stack>
                    </Grid>
                  )}

                  {!loading && totalItems > itemsPerPage && (
                    <Grid paddingY={5} item xs={12} md={9}>
                      <Stack
                        spacing={3}
                        direction="row"
                        display="flex"
                        justifyContent="center"
                        alignItems="center"
                      >
                        {
                          page === 1 ?
                            <Iconify
                              icon="ep:arrow-left"
                              sx={{
                                color: 'transparent',
                                height: 22,
                                width: 22,
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                            /> :
                            <Iconify
                              icon="ep:arrow-left"
                              sx={{
                                height: 22,
                                width: 22,
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() => { showPrevious() }}
                            />
                        }

                        <Stack spacing={1} direction="row">
                          <Typography color="#5E17EB">{page}</Typography>
                          <Typography>sur</Typography>
                          <Typography>{totalPage}</Typography>
                        </Stack>
                        {
                          page === totalPage ?
                            <Iconify
                              icon="ep:arrow-right"
                              sx={{
                                color: 'transparent',
                                height: 22,
                                width: 22,
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                            /> :
                            <Iconify
                              icon="ep:arrow-right"
                              sx={{
                                height: 22,
                                width: 22,
                                "&:hover": {
                                  cursor: "pointer",
                                },
                              }}
                              onClick={() => { showNext() }}
                            />
                        }
                      </Stack>
                    </Grid>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        {/** map */}
        {smUp && mdUp && (
          <Grid item xs={5}>
            <Box
              sx={{ bgcolor: "gainsboro", width: "100%", position: "fixed" }}
            >
              <MapsSearch
                loading={isLoaded}
                centerC={center}
                eventsList={events}
                hover={hoverE}
              />
            </Box>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default SearchPage;
