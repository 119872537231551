import React, { useState, useEffect } from "react";
import { Box, Button, Typography, Switch, Dialog, DialogTitle, DialogContent, DialogActions, Stack, Grid, Divider, styled } from "@mui/material";
import Cookies from "js-cookie";
import { cookieCategories } from "./cookieConfig";
import CustomButton from "../CustomButton";
import CustomSwitch from "../CustomSwitch";



const CookieConsent = () => {
    const [isBannerVisible, setIsBannerVisible] = useState(false);
    const [isBannerDetailVisible, setIsBannerDetailVisible] = useState(false);
    const [preferences, setPreferences] = useState(
        Object.keys(cookieCategories).reduce((acc, category) => {
            acc[category] = cookieCategories[category].default;
            return acc;
        }, {})
    );

    // Vérifie si les préférences existent déjà
    useEffect(() => {
        const existingPreferences = Cookies.get("cookiePreferences");
        console.log(existingPreferences)
        console.log(existingPreferences)
        if (!existingPreferences) {
            setIsBannerVisible(true);
        } else {
            const parsedPreferences = JSON.parse(existingPreferences);
            setPreferences(parsedPreferences);
            loadScripts(parsedPreferences);
        }
    }, []);

    const handleAcceptAll = () => {
        const allPreferences = Object.keys(cookieCategories).reduce((acc, category) => {
            acc[category] = true;
            return acc;
        }, {});
        savePreferences(allPreferences);
    };

    const savePreferences = (prefs) => {
        Cookies.set("cookiePreferences", JSON.stringify(prefs), { expires: 365 });
        setPreferences(prefs);
        setIsBannerVisible(false);
        loadScripts(prefs);
    };

    const loadScripts = (prefs) => {
        if (prefs.statistics) loadAnalytics();
        if (prefs.marketing) loadMarketingScripts();
    };

    const loadAnalytics = () => {
        if (!document.querySelector("#analytics-script")) {
            const script = document.createElement("script");
            script.id = "analytics-script";
            script.src = "https://www.google-analytics.com/analytics.js";
            script.async = true;
            document.body.appendChild(script);
        }
    };

    const loadMarketingScripts = () => {
        if (!document.querySelector("#marketing-script")) {
            const script = document.createElement("script");
            script.id = "marketing-script";
            script.src = "https://example-marketing-pixel.com/script.js";
            script.async = true;
            document.body.appendChild(script);
        }
    };

    return (
        <>
            {isBannerVisible && (
                <Stack
                spacing={2}
                    sx={{
                        position: "fixed",
                        bottom: 0,
                        left: "5%",
                        right: "5%",
                        backgroundColor: "#1F1F1F",
                        color: 'white',
                        marginBottom: 2,
                        padding: "16px",
                        boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
                        // boxShadow: "0 -2px 10px rgba(0,0,0,0.1)",
                        zIndex: 9999,
                        borderRadius: "10px"
                    }}
                >
                    {
                        isBannerDetailVisible && <Stack spacing={3} sx={{ overflowX: "auto", overflowY: "auto" }}>
                            {Object.entries(cookieCategories).map(([key, category]) => (
                                <>
                                <Stack spacing={1}>
                                    <Stack direction={'row'} justifyContent={'space-between'}>
                                        <Typography variant="body1"><strong>{category.name}</strong></Typography>
                                        <CustomSwitch checked={preferences[key]} onChange={() =>
                                                setPreferences((prev) => ({ ...prev, [key]: !prev[key] }))
                                            }/>
                                    </Stack>
                                    <Typography variant="body2">{category.description}</Typography>
                                </Stack>
                                <Divider sx={{bgcolor: "white"}}/></>
                            ))}

                        </Stack>
                    }

                    {
                        isBannerDetailVisible ?



                            <Stack direction={'row'} spacing={2} justifyContent={{ xs: 'center', sm: 'right' }}>
                                <CustomButton type={'secondaire'} buttonText={'Annuler'} onClick={() => setIsBannerVisible(true)} />
                                <CustomButton type={'primaire'} buttonText={'Sauvegarder'} onClick={() => savePreferences(preferences)} />
                            </Stack>


                            : <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent={{ xs: 'center', sm: 'space-between' }} alignItems={'center'}>

                                <Typography>
                                    Nous utilisons des cookies pour améliorer votre expérience. Personnalisez vos préférences.
                                </Typography>
                                <Stack direction={'row'} spacing={2} justifyContent={{ xs: 'center', sm: 'space-between' }}>
                                    <CustomButton type={'primaire'} buttonText={'Tout Accepter'} onClick={handleAcceptAll} />
                                    <CustomButton type={'secondaire'} buttonText={'Personnaliser'} onClick={() => { setIsBannerDetailVisible(true) }} />
                                </Stack>
                            </Stack>
                    }
                </Stack>
            )}

        </>
    );
};

export default CookieConsent;
